:local(.cnt) {
    text-align: center;
    align-items: center;
    display: flex;
    width: 100%;
    height: 100%;
}

:local(.img) {
    margin: 0 auto;
    width: 800px;
    max-width: 100%;
}

@media (min-width:640px) {
    :local(.img) {
        width: auto;
        max-width: 800px;
        max-height: 100vh;
    }
}