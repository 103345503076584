* {
  box-sizing: border-box;
}

:root {
  --cyan-color: #54D1EF;
  --pink-color: #FFF5F5;
  --morado-color: #47224A;
  --main-color: var(--pink-color);
  --secondary-color: var(--cyan-color);
}

html {
  height: 100%;
}

body {
  background: var(--pink-color);
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

.react-player iframe {
  height: 100% !important;
}