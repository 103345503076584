:local(.logo){
}
:local(.logo img){
  width:200px;
}
:local(.cnt){
  display:grid;
  grid-template-rows: 1fr;
  font-family: 'Montserrat', 'Helvetica', 'Arial';
  align-items: center;
  justify-content: center;
  height:100vh;
 
}
