:local(.cnt) {
    max-width: 1200px;
    width: 100%;
    margin: 0px auto;
    color: #222;
    padding: 0;
    display: grid;
    grid-template-rows: auto 1fr;
}

:local(.header) {
    text-align: center;
    padding-top: 30px;

    :local(.logo img) {
        width: 100px;

        @media (max-width:640px) {
            width: 70px;
        }
    }

}

:local(.section) {
    margin-bottom: 50px;
    margin-top: 50px;
}

:local(.content) {
    margin-top: 20px;
    display: grid;
    gap: 10px;

    @media (min-width:640px) {
        grid-template-columns: 5fr 3fr;
        grid-template-rows: auto 1fr;
        grid-template-areas: "navplayer sidebar"
            "info sidebar";

    }

    @media (max-width:640px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
        grid-template-areas: "navplayer"
            "info"
            "sidebar";

    }
}

:local(.navplayer) {
    grid-area: navplayer;

    :local(.video) {
        border-radius: 0px;
        overflow: hidden;

        @media (max-width:640px) {
            border-radius: 0;
        }
    }
}

:local(.videos) {
    margin-bottom: 20px;
    margin-top: 20px;

    :local(.videoPart) {
        display: inline-block;
        padding: 10px 15px;
        border-radius: 5px;
        cursor: pointer;

        &:local(.current) {
            background: #e0bdc1;
            font-weight: bold;
        }
    }
}


:local(.sidebar) {
    grid-area: sidebar;
    height: 100%;
    display: grid;
}

:local(.lessons) {
    display: grid;
    grid-template-rows: auto 1fr;

    border-radius: 5px;
    background: #ffffff99;

    @media (max-width:640px) {}

    :local(.title) {
        color: #444;
        font-size: 18px;
        font-weight: bold;
        margin: 0 0 10px 0;
        padding: 10px;
    }

    :local(.list) {

        overflow-y: auto;

    }

    :local(.lesson) {
        padding: 20px;
        cursor: pointer;
        border-bottom: 1px solid #00000022;

        :local(.date) {
            font-size: 12px;
        }

        &:local(.current) {
            background: #e0bdc1;
            font-weight: bold;
        }
    }
}

:local(.info) {
    grid-area: info;
    padding: 10px;

    :local(.name) {
        font-size: 18px;
    }

    :local(.date) {
        font-size: 12px;
    }

    :local(.description) {
        margin-top: 20px;
        font-size: 14px;
    }

    :local(.otherFiles) {
        background: #ffffffaa;
        padding: 10px;
        margin: 10px 0;
        border-radius: 5px;


        :local(.title) {
            color: #444;
            font-size: 14px;
            margin-bottom: 10px;
        }

        :local(.file) {
            display: grid;
            font-size: 12px;
            grid-template-columns: auto 1fr;
            align-items: flex-start;
            gap: 10px;

            a {
                color: #444;
            }
        }
    }
}

:local(.files) {
    display: grid;
    padding: 20px;
    grid-template-columns: 1fr 1fr;
    gap: 40px 30px;
    grid-template-areas: "carta infografia"
        "audio audio";

    img {
        width: 100%;
    }

    :local(.carta) {
        grid-area: carta;
    }

    :local(.infografia) {
        grid-area: infografia;
    }

    :local(.audio) {
        grid-area: audio;
    }
}

:local(.footer) {
    img {
        width: 100%;
        max-width: 1120px;
        display: block;
        margin: 0;
        padding: 0;
    }
}

:local(.downloadButton) {
    border-radius: 50px;
    color: #222;
    display: block;
    margin-top: 10px;
    background: #44444411;
    text-decoration: none;
    padding: 10px;
    font-size: 12px;
}

:local(.contentF) {
    margin-top: 20px;
    display: grid;
    gap: 10px;
}

:local(.notFound) {
    text-align: center;
    display: grid;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 22px;
    font-weight: 500;
    color: var(--morado-color);

}