:local(.cnt) {
    text-align: center;
    max-width: 800px;
    width: 100%;
    height: 100%;
    margin: 0px auto;
    padding: 0;
    display: grid;
    grid-template-rows: 1fr auto;
}

:local(.inner) {
    :local(.header) {
        width: 100%;
        margin-bottom: 20px;
    }
}

:local(.logo) {}

:local(.logo img) {
    width: 100px;
}

:local(.section) {
    margin-bottom: 50px;
    margin-top: 50px;
}

:local(.title) {
    color: #fff;
    font-size: 24px;
    margin: 50px 0 0;
}

:local(.signature) {
    margin: 50px 0 20px 0;

    img {
        width: 100%;
        max-width: 748px;
    }

    @media (max-width:640px) {
        margin: 10px 0 10px 0;
    }
}

:local(.files) {
    display: grid;
    padding: 20px;
    gap: 40px 30px;

    &:local(.normal) {
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "carta infografia"
            "audio audio";

    }

    &:local(.transitos_planetarios) {
        grid-template-columns: 1fr;
        grid-template-areas: "video "
            "audio";

    }

    img {
        width: 100%;
        border-radius: 5px;
        aspect-ratio: .6797;
    }

    :local(.carta) {
        grid-area: carta;
    }

    :local(.infografia) {
        grid-area: infografia;
    }

    :local(.video) {
        grid-area: video;
    }

    :local(.audio) {
        grid-area: audio;

        audio {
            width: 100%;

        }

        audio::-webkit-media-controls-play-button,
        audio::-webkit-media-controls-panel {
            background-color: #E8D9DA;
            color: #fff;
        }
    }
}

:local(.footer) {
    img {
        width: 100%;
        max-width: 1120px;
        display: block;
        margin: 0;
        padding: 0;
    }
}

:local(.downloadButton) {
    border-radius: 50px;
    color: #111;
    display: block;
    margin-top: 10px;
    background: #E8D9DA;
    text-decoration: none;
    padding: 10px;
    font-size: 12px;
}

:local(.notFound) {
    text-align: center;
    display: grid;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 22px;
    font-weight: 500;
    color: var(--morado-color);

}