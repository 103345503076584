:local(.cnt) {
    text-align: center;
    border-top: 5px solid #E8D9DA;
    display: grid;
    grid-template-rows: 1fr auto auto;
    align-self: flex-start;
    color: #4a4142;

    :local(.info) {
        padding: 40px 20px;
    }

    :local(.title) {
        font-size: 18px;
        font-weight: 600;
    }

    :local(.img) {
        width: 100%;
    }

    :local(.description) {
        font-size: 14px;
        margin-top: 10px;
    }

    :local(.button) {
        border-radius: 50px;
        color: #111;
        display: block;
        cursor: pointer;
        margin-top: 10px;
        background: #E8D9DA;
        text-decoration: none;
        padding: 15px;
        font-size: 16px;

        @media (max-width:640px) {
            font-size: 12px;
        }
    }

    :local(.purchase_button) {
        background: #0c9;
        color: #ffffffcc;
        font-weight: 700;
    }
}