:local(.cnt) {
    text-align: center;
    max-width: 1200px;
    width: 100%;
    margin: 0px auto;
    height: auto;
    padding: 0;
    display: grid;
    grid-template-rows: 1fr auto;
}

:local(.inner) {
    :local(.header) {
        width: 100%;
        margin-bottom: 20px;
    }
}

:local(.logo) {}

:local(.logo img) {
    width: 100px;
}

:local(.section) {
    margin-bottom: 50px;
    margin-top: 50px;
}

:local(.title) {
    color: #fff;
    font-size: 24px;
    margin: 50px 0 0;
}

:local(.signature) {
    margin: 50px 0 20px 0;

    img {
        width: 100%;
        max-width: 748px;
    }

    @media (max-width:640px) {
        margin: 10px 0 10px 0;
    }
}

:local(.items) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
    margin-top: 100px;
    margin-bottom: 100px;

    @media (max-width:640px) {
        padding: 10px;
        grid-template-columns: 1fr 1fr;
    }
}




:local(.footer) {
    img {
        width: 100%;
        max-width: 800px;
        display: block;
        margin: 0 auto;
        padding: 0;
    }
}